










import { Vue, Component } from 'vue-property-decorator';

@Component
export default class TeamView extends Vue {
  mounted() {
    this.$bus.$emit('title-change', 'Team', '/team');
  }
}
