import { db, firestore } from '@/firebase';
import { Icebreaker } from './models';

export const teamService = {
  adminRef: db.collection('admin'),
  usersRef: db.collection('users'),
  teamsRef: db.collection('teams'),

  // Admin
  async updateAdmin(docId: string, adminData: firestore.UpdateData) {
    await this.adminRef.doc(docId).update(adminData);
  },
  async getAllIcebreakers() {
    return await this.adminRef.doc('icebreakers').get();
  },
  async createIcebreaker(icebreaker: Icebreaker) {
    await this.updateAdmin('icebreakers', { [`${icebreaker.id}`]: icebreaker });
  },
  async updateIcebreaker(icebreaker: Icebreaker) {
    await this.updateAdmin('icebreakers', { [`${icebreaker.id}`]: icebreaker });
  },
  async deleteIcebreaker(icebreakerId: string) {
    await this.updateAdmin('icebreakers', { [`${icebreakerId}`]: firestore.FieldValue.delete() });
  },
};
