



































































import { Vue, Component, VModel } from 'vue-property-decorator';
import { getId } from '@/utils';
import { Icebreakers } from '../models';

@Component
export default class IcebreakersImport extends Vue {
  @VModel({ type: Boolean })
  showDialog!: boolean;

  items: string | null = null;
  category: string | null = null;
  tags: string[] | null = [];
  showCategory = false;
  showTags = false;
  allTags = [];

  reset() {
    this.items = null;
    this.category = null;
    this.tags = [];
    this.showCategory = false;
    this.showTags = false;
  }
  close() {
    this.reset();
    this.showDialog = false;
  }

  importItems() {
    if (!this.items) return;
    const itemsArray: Icebreakers = this.items!.split(/[\r\n]+/)
      .filter((v) => v.length)
      .map((v) => {
        return {
          id: getId(6),
          title: v.trim(),
          category: this.category,
          tags: this.tags,
        };
      });
    this.$emit('import', itemsArray);
    this.close();
  }
}
