













import { Vue, Component } from 'vue-property-decorator';
import { Icebreakers } from '../models';
import { teamService } from '../services';
import IcebreakersImport from '../components/IcebreakersImport.vue';

@Component({ components: { IcebreakersImport } })
export default class IcebreakersView extends Vue {
  showDialog = false;

  async importItems(items: Icebreakers) {
    console.log('Import', items);
    for (const item of items) {
      await teamService.createIcebreaker(item);
      console.log('Item', item);
    }
    this.$bus.$emit('snackbar-notify', `Icebreakers successfully imported (${items.length} items)`, 'success');
  }
}
